import Network from "./Network";
import isGoogleConversion from "../../helpers/IsGoogleConversion.ts";
import IsBingConversion from "../../helpers/IsBingConversion.ts";

class SmartResponseNetwork extends Network {
  constructor() {
    super();
    this.sourceKey = "s3";
    this.refererKey = "s2";
    this.guidKey = "s1";
    this.network = "smartresponse";
  }
  /*
  * Set network specific params
  * @param link
  */
  public setNetworkParams(link: HTMLAnchorElement): object {
    let params = super.setNetworkParams(link);

    if (this.gclidCookie && isGoogleConversion(this.daSiteCookie)) {
      params = {
        ...params,
        s4: this.gclidCookie,
      };
    } else if (this.msclkidCookie && IsBingConversion(this.daSiteCookie)) {
      params = {
        ...params,
        s4: this.msclkidCookie,
      };
    }

    if (this.acIdCookie) {
      params = {
        ...params,
        s5: this.acIdCookie,
      };
    }

    return params;
  }
}

export default SmartResponseNetwork;
