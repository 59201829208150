import TrackWiseNetwork from "./TrackWiseNetwork";
import AdserviceNetwork from "./AdserviceNetwork";
import AdtractionNetwork from "./AdtractionNetwork";
import AddrevenueNetwork from "./AddrevenueNetwork";
import PartnerAdsNetwork from "./PartnerAdsNetwork";
import SmartResponseNetwork from "./SmartResponseNetwork.ts";

const NetworkFactory = (link: HTMLAnchorElement) => {
  switch (true) {
    case link.href.includes("affiliate.salestring.com") ||
      link.href.includes("link.trackwise.dk"):
      return new TrackWiseNetwork();

    case link.href.includes("online.adservicemedia.dk") ||
      link.href.includes("aslinkhub.com"):
      return new AdserviceNetwork();

    case link.href.includes("secure.smartresponse-media.com"):
      return new SmartResponseNetwork();

    case link.href.includes("a=") && link.href.includes("as="):
      return new AdtractionNetwork();

    case link.href.includes("addrevenue"):
      return new AddrevenueNetwork();

    case link.href.includes("partner-ads"):
      return new PartnerAdsNetwork();
  }
};

export default NetworkFactory;
